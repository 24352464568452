import { Component, OnInit } from '@angular/core';

import { MatSnackBar } from '@angular/material/snack-bar';

import { Article } from '../../../interfaces/article';

import { DialogService} from '../../../services/dialog.service';
import { ArticleService } from '../../../services/article.service';
import { ProcessService } from '../../../services/process.service';

import { APP_URL } from '../../../app.config';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss']
})
export class ArticlesComponent implements OnInit {

  url = APP_URL;

  articles: Article[];

  constructor(private dialogService: DialogService,
              private snackBar: MatSnackBar,
              private articleService: ArticleService,
              private processService: ProcessService) { }

  ngOnInit() {
    this.processService.setProcess(true);
    this.articleService.getArticles()
      .pipe(untilDestroyed(this))
      .subscribe(
        (data) => {
          this.articles = data;
          this.processService.setProcess(false);
        }, () => {
          this.processService.setProcess(false);
          this.snackBar.open('Сервіс недоступний', 'Помилка');
        }, () => {
          this.processService.setProcess(false);
        });
  }

  delete(article: Article) {
    this.dialogService.openConfirmDialog('Видалення статті', 'Ви впевнені, що хочете повністю видалити цю статтю?')
      .pipe(untilDestroyed(this))
      .subscribe((ok) => {
        if (ok) {
          this.articleService.deleteArticle(article)
            .catch((reason) => console.log(reason));
        }
      });
  }

}

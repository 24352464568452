import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ActivationGuard implements CanActivate {

  constructor(
    private afa: AngularFireAuth,
    private snackBar: MatSnackBar
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.afa.authState
      .pipe(
        map((user) => {
          if (user) {
            this.snackBar.open('На жаль, ви вже увійшли в систему.', 'Попередження');
            return false;
          } else {
            return true;
          }
        })
      );
  }
}

<app-toolbar></app-toolbar>
<div class="app-card">
  <mat-card>
    <mat-card-header>
      <mat-card-title>{{ article.id === 'new' ? 'Створення' : 'Редагування' }}&nbsp;статті</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="articleForm" (ngSubmit)="saveArticle()" novalidate>
        <mat-form-field>
          <input matInput
                 placeholder="Назва"
                 formControlName="title"
                 minlength="0"
                 maxlength="992">
          <mat-error *ngIf="articleForm.controls.title.invalid">
            Потрібна назва статті
          </mat-error>
        </mat-form-field>
        <div class="app-editor">
          <span class="label">Зміст</span>
          <ckeditor [config]="config" formControlName="content"></ckeditor>
          <div class="error">
            <span *ngIf="articleForm.controls.content.invalid">
              Потрібен супровідний текст
            </span>
          </div>
        </div>
        <mat-checkbox
          formControlName="visible">
          Видимість
        </mat-checkbox>
        <mat-card-actions>
          <button mat-raised-button [disabled]="articleForm.invalid">Зберегти</button>
          <a mat-button (click)="onCancel()">Відміна</a>
        </mat-card-actions>
      </form>
    </mat-card-content>
  </mat-card>
</div>

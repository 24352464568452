import { Component, OnInit } from '@angular/core';

import { APP_URL } from '../../../app.config';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  url = APP_URL;

  constructor() { }

  ngOnInit() {
  }

}

<h2 mat-dialog-title>{{ title }}</h2>
<mat-dialog-content>{{ message }}</mat-dialog-content>
<mat-dialog-actions>
  <a mat-raised-button
     (click)="confirmDialogRef.close(true)">
    OK
  </a>
  <a mat-button
     (click)="confirmDialogRef.close()">
    Отмена
  </a>
</mat-dialog-actions>

import { Component } from '@angular/core';

import { Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';

import { AuthenticationService } from '../../services/authentication.service';
import { DialogService } from '../../services/dialog.service';
import { ProcessService } from '../../services/process.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent {

  logo = 'assets/img/logo.png';
  text = 'ТОВ «Енергодосвід»';

  constructor(public authenticationService: AuthenticationService,
              private snackBar: MatSnackBar,
              private dialogService: DialogService,
              private processService: ProcessService,
              private router: Router) { }

  login() {
    this.dialogService.openSignInDialog();
  }

  logout() {
    this.authenticationService.signOut()
      .catch((reason) => console.log(reason));
  }

  process() {
    return this.processService.process;
  }
}

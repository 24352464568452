<mat-card>
  <mat-card-header>
    <mat-card-title>Вхід</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="signInForm" (ngSubmit)="onSignIn()" novalidate>
      <mat-form-field>
        <input matInput
               placeholder="Ел. пошта"
               formControlName="email"
               minlength="0"
               maxlength="96">
        <mat-icon matSuffix>email</mat-icon>
        <mat-error *ngIf="signInForm.controls.email.invalid">{{getErrorMessage()}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <input matInput
               type="password"
               placeholder="Пароль"
               formControlName="password"
               minlength="8"
               maxlength="96"
               [type]="hide ? 'password' : 'text'">
        <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
      </mat-form-field>
      <mat-card-actions>
        <button mat-raised-button>Авторизуватись</button>
        <a mat-button (click)="onClose()">Відміна</a>
        <a mat-button (click)="onForgotPassword()">Забули пароль?</a>
      </mat-card-actions>
    </form>
  </mat-card-content>
</mat-card>

/**
 * Angular 2 decorators and services
 */
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

export const ROOT_SELECTOR = 'app-root';

/**
 * App Component
 * Top Level Component
 */
@Component({
  selector: ROOT_SELECTOR,
  encapsulation: ViewEncapsulation.None,
  styleUrls: [
    './app.component.scss',
    './app.theme.scss'
  ],
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  constructor(private meta: Meta,
              private title: Title) {
  }

  ngOnInit() {
    this.title.setTitle('ТОВ «Енергодосвід»');
  }
}

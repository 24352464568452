import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { MatSnackBar } from '@angular/material/snack-bar';

import { Article } from '../../../../interfaces/article';

import { ArticleService } from '../../../../services/article.service';
import { ProcessService } from '../../../../services/process.service';

import { editorConfig } from '../../../../editor.config';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit {

  article: Article = {} as Article;

  articleForm: FormGroup;

  config: any;

  constructor(
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private articleService: ArticleService,
    private processService: ProcessService,
    private activatedRoute: ActivatedRoute,
    private router: Router) {

    this.config = editorConfig;

    this.articleForm = this.formBuilder.group({
      title: [null, Validators.required],
      content: [null, Validators.required],
      visible: null
    });
  }

  ngOnInit() {
    this.activatedRoute.params
      .pipe(untilDestroyed(this))
      .subscribe((params) => {
        this.article.id = params['id'];

        if (this.article.id !== 'new') {
          this.processService.setProcess(true);
          this.articleService.showArticle(this.article)
            .pipe(untilDestroyed(this))
            .subscribe(
              (data) => {
                if (data.exists) {
                  const article = data.data() as Article;
                  this.articleForm.patchValue({
                    title: article.title,
                    content: article.content,
                    visible: article.visible
                  });
                } else {
                  this.snackBar.open('Стаття не знайдена', 'Помилка');
                }
              }, () => {
                this.snackBar.open('Сервіс недоступний', 'Помилка');
              }, () => {
                this.processService.setProcess(false);
              });
        } else {
          return;
        }
      });
  }

  saveArticle() {
    this.processService.setProcess(true, this.articleForm);
    const article = {...this.article, ...this.articleForm.value} as Article;
    if (article.id === 'new') {
      const omit = (object, property) => {
        const {[property]: _, ...result} = object;
        return result;
      };
      this.articleService.createArticle(omit(article, 'id')).then(
        () => {
          this.router.navigate(['./system/articles'])
            .then(() => this.snackBar.open('Стаття успішно створена', 'Успішно'))
            .catch((reason) => console.log(reason));
        },
        (error) => {
          this.snackBar.open(error.message, 'Помилка');
        })
        .finally(() => this.processService.setProcess(false, this.articleForm));
    } else {
      this.articleService.updateArticle(article).then(
        () => {
          this.router.navigate(['./system/articles'])
            .then(() => this.snackBar.open('Стаття успішно оновлена', 'Успішно'))
            .catch((reason) => console.log(reason));
        },
        (error) => {
          this.snackBar.open(error.message, 'Помилка');
        })
        .finally(() => this.processService.setProcess(false, this.articleForm));
    }
  }

  onCancel() {
    this.router.navigate(['./system/articles'])
      .catch((reason) => console.log(reason));
  }
}

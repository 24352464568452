import { Component } from '@angular/core';

import { Router } from '@angular/router';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';

import { AuthenticationService } from '../../../../services/authentication.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent {

  signInForm: FormGroup;

  hide = true;

  constructor(private dialog: MatDialog,
              private dialogRef: MatDialogRef<SignInComponent>,
              private snackBar: MatSnackBar,
              private formBuilder: FormBuilder,
              private authenticationService: AuthenticationService,
              private router: Router) {

    this.signInForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required]]
    });
  }

  onSignIn() {
    this.authenticationService.signIn(this.signInForm.value.email, this.signInForm.value.password, this.dialogRef)
      .catch((reason) => console.log(reason));
  }

  onForgotPassword() {
    this.onClose();
    this.dialog.open(ForgotPasswordComponent, { disableClose: true });
  }

  getErrorMessage() {
    return this.signInForm.get('email').hasError('required') ? 'Потрібна адреса ел. пошти' :
      this.signInForm.get('email').hasError('email') ? 'Будь ласка, введіть дійсну адресу ел. пошти' :
        null;
  }

  onClose(): void {
    this.dialogRef.close();
  }
}

<app-toolbar></app-toolbar>
<table class="app-list">
  <tr>
    <td colspan="2">
      <a [routerLink]=" ['/system/dashboard'] ">Головна</a>
      |
      <a [href]="url">Просмотр</a>
      |
      <a [routerLink]=" ['/system/services/new'] ">Створити послугу</a>
    </td>
  </tr>
  <tr *ngFor="let service of services">
    <td>
      <img [alt]="service.visible ? 'видимо' : 'приховано'"
           [src]="service.visible ? 'assets/img/yes.png' : 'assets/img/no.png'">
    </td>
    <td>
      <a [routerLink]=" ['/system/services', service.id] ">
        {{ service.title }}
      </a>
    </td>
  </tr>
</table>

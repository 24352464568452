<div id="progress">
  <mat-progress-bar mode="indeterminate" *ngIf="process()"></mat-progress-bar>
</div>
<mat-toolbar>
  <mat-toolbar-row>
    <img [alt]="text" [src]="logo" class="app-logo">
    <span class="toolbar-spacer"></span>
    <div *ngIf="authenticationService.isLoggedIn; else showLogin">
      <a href="javascript:void(0)" (click)="logout()">Вихід</a>
    </div>
    <ng-template #showLogin>
      <a href="javascript:void(0)" (click)="login()">Вхід</a>
    </ng-template>
  </mat-toolbar-row>
</mat-toolbar>

<app-toolbar></app-toolbar>
<table class="app-list">
  <tr>
    <td colspan="2">
      <a [routerLink]=" ['/system/dashboard'] ">Головна</a>
      |
      <a [href]="url">Просмотр</a>
      |
      <a [routerLink]=" ['/system/articles/new'] ">Створити статтю</a>
    </td>
  </tr>
  <tr *ngFor="let article of articles">
    <td>
      <img [alt]="article.visible ? 'видимо' : 'приховано'"
           [src]="article.visible ? 'assets/img/yes.png' : 'assets/img/no.png'">
    </td>
    <td>
      <a [routerLink]=" ['/system/articles', article.id] ">
        {{ article.title }}
      </a>
    </td>
  </tr>
</table>

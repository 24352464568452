<app-toolbar></app-toolbar>
<div class="app-card">
  <mat-card>
    <mat-card-header>
      <mat-card-title>{{ service.id === 'new' ? 'Створення' : 'Редагування' }}&nbsp;послуги</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="serviceForm" (ngSubmit)="saveService()" novalidate>
        <mat-form-field>
          <input matInput
                 placeholder="Назва"
                 formControlName="title"
                 minlength="0"
                 maxlength="992">
          <mat-error *ngIf="serviceForm.controls.title.invalid">
            Потрібна назва послуги
          </mat-error>
        </mat-form-field>
        <div class="app-editor">
          <span class="label">Зміст</span>
          <ckeditor [config]="config" formControlName="content"></ckeditor>
          <div class="error">
            <span *ngIf="serviceForm.controls.content.invalid">
              Потрібен супровідний текст
            </span>
          </div>
        </div>
        <mat-checkbox
          formControlName="visible">
          Видимість
        </mat-checkbox>
        <mat-card-actions>
          <button mat-raised-button [disabled]="serviceForm.invalid">Зберегти</button>
          <button mat-button type="button" [disabled]="serviceForm.invalid" (click)="onUpload()">Завантажити</button>
          <a mat-button (click)="onCancel()">Відміна</a>
        </mat-card-actions>
      </form>
      <div *ngIf="!hidden" class="inline">
        <div *ngFor="let id of service.publicIds">
          <cl-image
            public-id="{{ id }}"
            crop="scale"
            dpr="auto"
            width="200">
          </cl-image>
        </div>
      </div>
      <div class="thumbnails"></div>
    </mat-card-content>
  </mat-card>
</div>

<mat-card>
  <mat-card-header>
    <mat-card-title>Зміна пароля</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="forgotPasswordForm" (ngSubmit)="onResetPassword()" novalidate>
      <mat-form-field>
        <input matInput
               placeholder="Ел. пошта"
               formControlName="email"
               minlength="0"
               maxlength="96">
        <mat-icon matSuffix>email</mat-icon>
        <mat-error *ngIf="forgotPasswordForm.controls.email.invalid">{{getErrorMessage()}}</mat-error>
      </mat-form-field>
      <mat-card-actions>
        <button mat-raised-button>Змінити пароль</button>
        <a mat-button (click)="onClose()">Відміна</a>
        <a mat-button (click)="onSignIn()">Вхід у систему</a>
      </mat-card-actions>
    </form>
  </mat-card-content>
</mat-card>

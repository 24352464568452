import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, PreloadAllModules } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

/*
 * Platform and Environment providers/directives/pipes
 */
import { environment } from 'environments/environment';
import { ROUTES } from './app.routes';
// App is our top level component
import { AppComponent } from './app.component';
import { APP_RESOLVER_PROVIDERS } from './app.resolver';

// Application wide providers
const APP_PROVIDERS = [
  ...APP_RESOLVER_PROVIDERS
];

import { ENV_FIREBASE_CONFIG } from './firebase.config';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';

import { SharedModule } from './modules/shared/shared.module';

import { CloudinaryModule, CloudinaryConfiguration } from '@cloudinary/angular-5.x';
import { Cloudinary } from 'cloudinary-core';

import { widgetConfig } from './widget.config';

const cloudinaryConfig: CloudinaryConfiguration = {
  cloud_name: widgetConfig.cloud_name,
  upload_preset: widgetConfig.upload_preset,
  api_key: widgetConfig.api_key,
  api_secret: widgetConfig.api_secret
};

import { ActivationGuard } from './guards/activation.guard';
import { AuthenticationGuard } from './guards/authentication.guard';

import { NoContentComponent } from './components/no-content/no-content.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { AuthenticationComponent } from './components/system/authentication/authentication.component';
import { DashboardComponent } from './components/system/dashboard/dashboard.component';

import { AuthenticationService} from './services/authentication.service';
import { ArticleService } from './services/article.service';
import { DialogService } from './services/dialog.service';
import { ServiceService } from './services/service.service';

import { SignInComponent } from './components/system/authentication/sign-in/sign-in.component';
import { ForgotPasswordComponent } from './components/system/authentication/forgot-password/forgot-password.component';
import { ArticlesComponent } from './components/system/articles/articles.component';
import { ArticleComponent } from './components/system/articles/article/article.component';
import { ServicesComponent } from './components/system/services/services.component';
import { ServiceComponent } from './components/system/services/service/service.component';
import { ConfirmDialogComponent } from './components/system/confirm-dialog/confirm-dialog.component';

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
  bootstrap: [ AppComponent ],
  declarations: [
    AppComponent,
    NoContentComponent,
    ProgressBarComponent,
    AuthenticationComponent,
    DashboardComponent,
    SignInComponent,
    ForgotPasswordComponent,
    ArticlesComponent,
    ArticleComponent,
    ServicesComponent,
    ServiceComponent,
    ConfirmDialogComponent
  ],
  /**
   * Import Angular's modules.
   */
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(ENV_FIREBASE_CONFIG),
    AngularFireAuthModule,
    AngularFirestoreModule,
    SharedModule,
    CloudinaryModule.forRoot({ Cloudinary }, cloudinaryConfig),
    HttpClientModule,
    RouterModule.forRoot(ROUTES, {
      useHash: Boolean(history.pushState) === false,
      preloadingStrategy: PreloadAllModules
    })
  ],
  /**
   * Expose our Services and Providers into Angular's dependency injection.
   */
  providers: [
    environment.ENV_PROVIDERS,
    APP_PROVIDERS,
    ActivationGuard,
    AuthenticationGuard,
    AuthenticationService,
    ArticleService,
    DialogService,
    ServiceService
  ],
  entryComponents: [
    SignInComponent,
    ForgotPasswordComponent,
    ConfirmDialogComponent
  ]
})
export class AppModule {}
